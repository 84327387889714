const moment = require('moment')
export const mobileRegex = /^0\d{9}$/
export const pinCodeRegex = /^\d{6}$/
export const TwoFACodeRegex = /^\d{6}$/
export const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-+=<>,.;:'"{}/\\[\]_`|~])[\w!@#$%^&*()\-+=<>,.;:'"{}/\\[\]_`|~]{8,}$/

export const shopList = [
  {
    name: "Kalli Paschim Lucknow",
    id: 'lko01'
  },
  {
    name: "Kalukhera Unnao",
    id: 'kl'
  },
  {
    name: "Purwa Unnao",
    id: 'purwa'
  },
  {
    name: "Harchandpur Raebareli",
    id: 'harchandpur'
  },
  {
    name: "Achalganj Unnao",
    id: 'achalganj'
  }
]

export const capitalizeFirstLetter = (string: string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}

export const formatNumber = (number: any) => {
  if (!number) {
    return 0
  }
  const denominations = ['', 'k', 'M', 'B', 't', 'q']
  let denominationIndex = 0

  while (number >= 1000 && denominationIndex < denominations.length - 1) {
    number /= 1000
    denominationIndex++
  }
  const formattedDenomination = denominations[denominationIndex]
  return `${USDFormatter(number)}${formattedDenomination}`
}

export const USDFormatter = (amount: any) => {
  if (amount) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
  }
}

export function formatDate(inputDate: any) {
  const formattedDate = moment(inputDate).format('MM/DD/YYYY hh:mm a')
  return formattedDate
}

export const ellipseString = (string: string, maxLength: number) => {
  if (string.length <= maxLength) {
    return string
  }
  return string.slice(0, maxLength) + "..."
}

export const blankSpacesRegex = /^(?! )(?!.*\s{2})\S.*$/ // Regex for remove spaces.
export const specialCharRegex = /^[a-zA-Z0-9\s_]*$/

export const onlyNumeric = (e: any) => { // Allow only numeric values
  const key = e.key
  const excludedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"]
  if (
    !(
      (key >= "0" && key <= "9") || // Numbers 0 to 9
      excludedKeys.includes(key) // Backspace, left arrow, right arrow, delete keys
    )
  ) {
    e.preventDefault()
  }
}

export function hasNullValues(obj: any) {
  for (const key in obj) {
    if (obj[key] === null) {
      return true // Found a null value
    }
  }
  return false // No null values found
}

export const allOptionForList = { id: "", name: "All Properties" }

export const returnPlural = (count: number, label: string) => {
  if (count !== 1) {
    return label + 's'
  } else {
    return label
  }
}

export const addCommaAfterThreeDigits = (number: number) => {
  return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// `queryFilters` is an array that defines various filters for querying or searching.
export const queryFilters = [
  'min_coc_return',
  'max_coc_return',
  'min_irr',
  'max_irr',
  'start_price',
  'end_price',
  'listing_type',
  'property_type',
  'address'
]

export function formatDateWithMoment(inputDateStr: any) {
  const inputDate = moment(inputDateStr)
  return inputDate.format("MMMM DD, YYYY")
}

export const sortByFormula = (array: any) => {
  return array.sort((a: any, b: any) => {
    const formulaA = a.quantity
    const formulaB = b.quantity
    return formulaB - formulaA
  })
}

export const getUnitName = (type: string) => {
  console.log('type: ', type)
  switch (type) {
    case 'stone':
      return 'Sqft'
    case 'tile':
      return 'Box'
    case 'chemical':
      return 'Bag'
    case 'mirror':
      return 'Unite'
    default:
      return 'Unite'
  }
}
